// src/context/SalesForceFormContext.js

import React, { createContext, useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const SalesForceFormContext = createContext();

export const SalesForceFormProvider = ({ children, pageContext }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: { nodeType: { eq: "Page" } }) {
            nodes {
              uri
              databaseId
              hide_subscribe_form {
                hideSubscribeForm
              }
            }
          }
        }
      `}
      render={data => {
        // Find the current page by URI or databaseId from pageContext
        const salesforceMap = new Map()

        for (const node of data.allWpPage.nodes) {
          salesforceMap.set(node.uri, Boolean(node.hide_subscribe_form?.hideSubscribeForm))
        }


        return (
          <SalesForceFormContext.Provider value={salesforceMap}>
            {children}
          </SalesForceFormContext.Provider>
        );
      }}
    />
  );
};

export const useSalesForceForm = () => useContext(SalesForceFormContext);
