import React from 'react'
import { createGlobalStyle } from 'styled-components'
import './src/assets/fonts/fonts.css'
import { SalesForceFormProvider, useSalesForceForm } from './src/context/SalesForceFormContext';

const HideError = createGlobalStyle`
  #___gatsby ~ iframe {
    display: none;
  }
`

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {process.env.NODE_ENV === 'preview' && <HideError />}
      {element}
    </>
  )
}

export const wrapPageElement = ({ element }) => {
  return <SalesForceFormProvider>{element}</SalesForceFormProvider>;
};
