exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-templates-blog-listing-js": () => import("./../../../src/templates/BlogListing.js" /* webpackChunkName: "component---src-templates-blog-listing-js" */),
  "component---src-templates-cancer-type-js": () => import("./../../../src/templates/CancerType.js" /* webpackChunkName: "component---src-templates-cancer-type-js" */),
  "component---src-templates-clinical-trial-js": () => import("./../../../src/templates/ClinicalTrial.js" /* webpackChunkName: "component---src-templates-clinical-trial-js" */),
  "component---src-templates-clinical-trials-listing-js": () => import("./../../../src/templates/ClinicalTrialsListing.js" /* webpackChunkName: "component---src-templates-clinical-trials-listing-js" */),
  "component---src-templates-glossary-js": () => import("./../../../src/templates/Glossary.js" /* webpackChunkName: "component---src-templates-glossary-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-press-release-js": () => import("./../../../src/templates/PressRelease.js" /* webpackChunkName: "component---src-templates-press-release-js" */),
  "component---src-templates-press-release-listing-js": () => import("./../../../src/templates/PressReleaseListing.js" /* webpackChunkName: "component---src-templates-press-release-listing-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/Search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/Support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

